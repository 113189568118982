import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';

import './footer-navigation.component.scss';

export const FooterNavigation = ({ label, to, steps, activeStep, variant, disabled, onClick, ...rest }) => {

    const stepMapper = Array.from(Array(steps).keys());

    return <div className={`hap-footer-navigation ${disabled ? 'disabled' : ''} ${variant === 'orange' ? 'variant-orange' : ''}`} {...rest}>
        <div className="steps">
            { stepMapper.map((_, step) => <div key={`step-${step}`} className={`step ${activeStep === step ? 'active' : ''}`}></div>) }
        </div>

        { onClick ? <a className="t-button" onClick={onClick}>{ label }</a> : <Link to={to}>{ label }</Link>     }
    </div>;
};